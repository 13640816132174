<template>
    <section class="content">
        <div class="card">
            <div class="card-body" style="align-self: center; display: flex;">
                <span style="align-self: center; font-size: initial;">Periode</span>&nbsp;&nbsp;
                <div class="input-group">
                  <input type="text" class="form-control" id="daterange" autocomplete="off">
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-calendar"></i></span>
                  </div>
                </div>
            </div>
        </div>
        <div class="table-responsivex">
            <table class="table table-hover" ref="tableuser" style="width: 100%;">
                <thead>
                    <tr>
                        <th>Kode</th>
                        <th>Tanggal</th>
                        <th>Cabang</th>
                        <th>Transaksi</th>
                        <th>No Transaksi</th>
                        <th>Masuk</th>
                        <th>Keluar</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody @click="handleClick">
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="5">Total:</th>
                        <td class="total-masuk">Rp 0</td>
                        <td class="total-keluar">Rp 0</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div
          class="modal"
          tabindex="-1"
          role="dialog"
          ref="formDialog"
          data-backdrop="static"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <form role="form" @submit="submitForm">
                <div class="modal-header">
                  <h5 class="modal-title">{{ formTitle }}</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                    <b>Please correct the following error(s):</b>
                    <ul>
                      <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  </p>
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <label class="control-label">TANGGAL</label>
                      <datepicker v-model="form.tanggal" id="tanggal" />
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Keterangan</label>
                      <input
                        id="nama_transaksi"
                        class="form-control"
                        v-model="form.keterangan"
                        type="text"
                      />
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Nominal Pengeluaran</label>
                        <currency-input id="nominal" class="form-control" currency="IDR" v-model="form.keluar" :precision="0" name="i_nominal" ref="nominal" autocomplete="off"/>
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="control-label">Catatan</label>
                      <textarea
                        class="form-control"
                        id="deskripsi"
                        rows="3"
                        v-model="form.catatan"
                        placeholder="Tuliskan catatan"
                      ></textarea>
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Cabang</label>
                        <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Unit</label>
                        <v-select :options="optUnit" v-model="form.unit_id" :reduce="opt => opt.id" />
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary">
                    Save changes
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" ref="formUpload" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form">
                        <div class="modal-header">
                            <h5 class="modal-title">Upload LPJ</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-12 form-group">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfile" id="inputFile" name="file" @change="uploadFoto(this)">
                                    <label class="custom-file-label" for="inputFile" ref="imgname">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button"  @click="submitUpload" class="btn btn-primary submit"><span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading?" Sedang menyimpan...":"Save changes" }}</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import Swal from "sweetalert2";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import datepicker from '@/components/Datepicker';
import Daterangepicker from 'daterangepicker';
import "daterangepicker/daterangepicker.css";
import { CurrencyInput } from "vue-currency-input";

window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

let idr = Intl.NumberFormat("en-ID", {
    style: "currency",
    currency: "IDR",
    useGrouping: true,
    maximumSignificantDigits: 3,
}); 

export default {
    name: 'DataGuru',
    components: {
        vSelect,
        datepicker,
        CurrencyInput,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            loading: false,
            optCabang: [],
            optUnit: [],
            optBranch: [],
            optBarang: [],
            optType: [
                {id: '0', label: 'Barang Masuk'},
                {id: '1', label: 'Barang Keluar'},
                {id: '2', label: 'Stock Opname'}
            ],
            form: {
                tanggal: '',
                branch_id: '',
                unit_id: '',
                keterangan: '',
                catatan: '',
                keluar: 0
            },
            date_start: moment().format('YYYY-MM-DD'),
            date_end: moment().format('YYYY-MM-DD'),
            formUpload: {
              id: '',
            },
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)

        authFetch('/management/inventaris/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optBranch = js.data;
            })

        authFetch('/management/inventaris/barang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optBarang = js.data;
            })

        authFetch("/keuangan/keuangan_cabang/unit")
            .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
            })
            .then((js) => {
                this.optUnit = js.data;
            });
    },
    methods: {
        handleClick(e) {
            console.log('asdf',e.srcElement.getAttribute('class'))
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            // if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
            //     this.$router.push('/inventaris-detail/'+e.srcElement.getAttribute('data-id'));
            // }
            if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
                this.formUploadLPJ(e.srcElement.getAttribute('data-id'))
            }
            if (e.srcElement.getAttribute('class') == 'download btn btn-primary') {
                this.loadingDownload = true;
                Swal.fire({
                      title: "Mohon tunggu...",
                      text: "Sedang mengambil data",
                      imageUrl: "images/ajaxloader.gif",
                      showConfirmButton: false,
                      allowOutsideClick: false,
                      buttons: false,
                    });
                this.download(e.srcElement.getAttribute('data-href'), e.srcElement.getAttribute('data-name'));
            }
            // if (e.srcElement.getAttribute('class') == 'download btn btn-primary') {
            //     this.loadingDownload = true;
            //     Swal.fire({
            //           title: "Mohon tunggu...",
            //           text: "Sedang mengambil data",
            //           imageUrl: "images/ajaxloader.gif",
            //           showConfirmButton: false,
            //           allowOutsideClick: false,
            //           buttons: false,
            //         });
            //     this.download(e.srcElement.getAttribute('data-href'), e.srcElement.getAttribute('data-name'));
            // }
            if (e.srcElement.getAttribute('class') == 'btn btn-info preview') {
                this.preview(e.srcElement.getAttribute('data-href'));
            }
            // if (e.srcElement.getAttribute('class') == 'btn btn-info preview') {
            //     this.preview(e.srcElement.getAttribute('data-href'));
            // }
        },
        onChange: function() {
            
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var self = this;
            this.errors = [];

            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/keuangan/keuangan_cabang';
            if (this.method == 'PUT') urlSubmit = '/keuangan/keuangan_cabang/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                });

            ev.preventDefault();
        },
        formUploadLPJ: function(id) {
            const e = this.$refs;
            this.formUpload.id = id
            $(e.imgname).html('Choose file');
            $(e.formUpload).modal('show');
        },
        uploadFoto : function (file) {
            const e = this.$refs;
            var file_data = $('#inputFile').prop('files');
            $(e.imgname).html(file_data[0].name);
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/LPJ-keuangan-cabang/');
            form_data.append('id', this.formUpload.id);
            this.formUpload = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/keuangan/keuangan_cabang/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                  'Content-Type': null,
                }
            })
        },
        submitUpload: function() {
            var self = this;
            this.loading = true;
            $('.submit').attr('disabled','disabled')
            var file_data = $('#inputFile').prop('files');
            if (file_data.length == 0) {
                Swal.fire({
                    icon: 'info',
                    title: 'Informasi',
                    text: 'Mohon upload LPJ!'
                })
                $('.submit').removeAttr('disabled')
                return false;
            }
            const e = this.$refs;
            authFetch('/keuangan/keuangan_cabang/upload_data', {
                method: 'POST',
                body: this.formUpload,
                headers: {
                  'Content-Type': null,
                }
            }).then((res) => {
                this.loading = false;
                if (res.status === 201) {

                } else if (res.status === 400) {}

                return res.json();
                
            }).then(js => {
              this.loading = false;
              $('.submit').removeAttr('disabled')
                this.errors = [];
                    if (!js.status) {
                        for (var key in js) {
                            if (js.hasOwnProperty(key)) {
                                this.errors.push(js[key])
                            }
                        }

                        return;
                    } else {
                        $(e.formUpload).modal('hide');
                        this.table.api().ajax.reload();
                        Swal.fire({
                            icon: 'success',
                            title: 'Informasi',
                            text: 'Data telah tersimpan.'
                        })
                        this.formUpload.id =  ''
                    }
            })
        },

        download: function(code, name) {
            authFetch("/accounting/pengajuan/download/"+code, {
                method: 'GET',
                headers: {
                'Content-Type': null,
                }
            })
            .then(response => {
              console.log(response)
              // const filename =  response.headers.get('Content-Disposition');//.split('filename=')[1];
              response.blob().then(blob => {
                  this.loadingDownload = false;
                  Swal.fire({
                      title: "Sukses",
                      text: "Load data berhasil",
                      showConfirmButton: false,
                      showOkButton: false,
                      buttons: false,
                      timer: 1000
                    });
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement('a');
                  a.href = url;
                  a.download = name;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
              });
            })
        },
        preview: function(data) {
            window.open(window.$apiUrl+data, "_blank");
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;

        let table = this.table = createTable(e.tableuser, {
            "title": "List",
            "roles": this.$route.params.roles,
            "ajax": "/keuangan/keuangan_cabang",
            scrollX: true,
            "columns": [
                { "data": "nama_transaksi" },
                { "data": "tanggal" },
                { "data": "branch_name" },
                { "data": "deskripsi" },
                { "data": "nomor_transaksi" },
                { "data": "nilai_masuk", sortable:false },
                { "data": "nilai_keluar", sortable:false },
                { "data": "file_transaksi", sortable:false },
            ],
            filterBy: [1, 2],
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
            order: [1, 'desc'],
            paramData: function ( d ) {
                d.date_start = self.date_start;
                d.date_end = self.date_end;
            },
            "rowCallback": function(row, data) {
                $('td:eq(5)', row).html(idr.format(data.nilai_masuk ? data.nilai_masuk : 0));
                $('td:eq(6)', row).html(idr.format(data.nilai_keluar ? data.nilai_keluar : 0));
                if (data.file_transaksi) {
                    $('td:eq(7)', row).html('<div class="row"><a type="button" class="btn btn-info preview" data-href="/file/view/'+data.file_transaksi+'" data-name="'+data.file_actual+'"><i class="far fa-eye" style="pointer-events:none"></i></a><a type="button" class="download btn btn-primary" data-href="'+data.file_transaksi+'" data-name="'+data.file_actual+'"><i class="fas fa-download" style="pointer-events:none"></i></a></div>');
                } else {
                    $("td:eq(7)", row).html('<a type="button" class="btn btn-primary" data-toggle="modal"style="color:white" data-id="'+data.id_transaksi+'">Upload</a>');
                }
                // $('td:eq(7)', row).html('<a type="button" class="btn btn-primary" data-id="'+data.id_transaksi+'" data-nilai="'+data.nilai_total+'" data-pembayaran="'+data.pembayaran+'">View</a>');
            },
            buttonClick: (evt) => { 
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Pengeluaran';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    // self.form = evt.data;
                    // self.method = 'PUT';
                    // self.errors = [];
                    // self.formTitle = 'Edit Pengeluaran';
                    // $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    // self.form = evt.data;
                    // authFetch('/akademik/data_guru/' + evt.data.id, {
                    //         method: 'DELETE',
                    //         body: 'id=' + evt.data.id
                    //     })
                    //     .then(res => {
                    //         return res.json();
                    //     })
                    //     .then(js => {
                    //         this.table.api().ajax.reload();
                    //     });
                }

            },
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api(), data;
     
                var masuk = 0;
                var keluar = 0;
                data.map(function(val,key){
                    masuk = masuk + parseFloat(val.nilai_masuk ? val.nilai_masuk : 0);
                    keluar = keluar + parseFloat(val.nilai_keluar ? val.nilai_keluar : 0);
                });

                $('.total-masuk').html(idr.format(masuk));
                $('.total-keluar').html(idr.format(keluar));
            },
            dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                {
                    extend: 'excel',
                    text: 'XLSX',
                    className: 'btn btn-success',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Keuangan Cabang '+self.date_start+' sd '+self.date_end;
                    },
                    filename: function(){
                        return 'Keuangan Cabang '+self.date_start+' sd '+self.date_end;
                    },
                },
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'btn btn-primary',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Keuangan Cabang '+self.date_start+' sd '+self.date_end;
                    },
                    filename: function(){
                        return 'Keuangan Cabang '+self.date_start+' sd '+self.date_end;
                    },
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    className: 'btn btn-info',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Keuangan Cabang '+self.date_start+' sd '+self.date_end;
                    },
                    filename: function(){
                        return 'Keuangan Cabang '+self.date_start+' sd '+self.date_end;
                    },
                },
                {
                    extend: 'csvHtml5',
                    text: 'CSV',
                    className: 'btn bg-gray-dark',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6],
                    },
                    title: function(){
                        return 'Keuangan Cabang '+self.date_start+' sd '+self.date_end;
                    },
                    filename: function(){
                        return 'Keuangan Cabang '+self.date_start+' sd '+self.date_end;
                    },
                },
            ],
        });
        new Daterangepicker(this.$refs.daterange, {
            }, function(d1, d2) {
            });
        $('#daterange').daterangepicker({ startDate: moment(), endDate: moment() });
        $('#daterange').on('apply.daterangepicker', function(ev, picker) {
            self.date_start = picker.startDate.format('YYYY-MM-DD')
            self.date_end = picker.endDate.format('YYYY-MM-DD')
            table.api().ajax.reload();
        });
    }
}
</script>